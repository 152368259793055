import InsightsIcon from '@mui/icons-material/Insights';
import urlConfigInternal from 'shared/config/url-config-internal';

const icons = {
    InsightsIcon
};

const analytics = {
    id: 'analytics',
    title: 'Analytics',
    type: 'item',
    url: urlConfigInternal.analytics,
    icon: icons.InsightsIcon
};

export default analytics;