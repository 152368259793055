import {useTheme} from '@mui/material/styles';
import PropTypes from 'prop-types';
import DrawerHeaderStyled from 'shared/ui/layout/MainLayout/Drawer/DrawerHeader/DrawerHeaderStyled';
import {LogoSectionL} from 'shared/ui/Logo';

const DrawerHeader = ({open}) => {
    const theme = useTheme();

    return (
        <DrawerHeaderStyled theme={theme} open={open}>
            <LogoSectionL/>
        </DrawerHeaderStyled>
    );
};

DrawerHeader.propTypes = {
    open: PropTypes.bool
};

export default DrawerHeader;
