import axios, {AxiosInstance, AxiosResponse} from 'axios';
import {ApiParams, appApiResp} from 'shared/api/ApiResponse';
import {appApiConfig} from 'shared/config/appConfig';
import {loginPath} from 'shared/config/url-config-internal';
import {_t} from 'shared/lib/i18n/I18n';
import AppModel from 'shared/model/AppModel';

axios.defaults.withCredentials = true;
const Api: AxiosInstance = axios.create({
    baseURL: appApiConfig.url,
    withCredentials: true,
    headers: {'Content-Type': 'application/json'},
    validateStatus: function (status) {
        return status === 400 || status === 422 || (status >= 200 && status < 300);
    }
});

Api.interceptors.response.use(function (response) {

    if ((response.status === 422) && response.headers?.[appApiConfig.debugHeader]) {
        AppModel.appDebugMessage = response?.data?.message;
    }

    return response;
}, function (error) {

    if (error?.response?.status === 401) {
        // AppModel.appError = _t('Unauthorized Access');
        AppModel.shutdown();

        if (window.location.pathname !== loginPath) {
            window.location.href = loginPath;
        }
    }

    if (error?.response?.status === 403) {
        AppModel.appError = _t('Forbidden');
    }

    if (error?.response?.status === 503) {
        AppModel.appError = _t(error?.response?.data?.message ?? 'Service Unavailable');
    }

    if (error?.response?.status === 500) {
        if (error?.response?.headers?.[appApiConfig.debugHeader]) {
            AppModel.appDebugMessage = error.response?.data?.message;
        } else {
            AppModel.appError = _t('Internal error');
        }
    }

    return Promise.reject();
});

const responseBody = (response: AxiosResponse) => response.data;

const appRequest = {
    get: (url: string, params?: ApiParams) => Api.get(url, {params: params}).then(responseBody),
    post: (url: string, body: any) => Api.post(url, body).then(responseBody),
    patch: (url: string, body: any) => Api.patch(url, body).then(responseBody),
    put: (url: string, body: any) => Api.put(url, body).then(responseBody),
    delete: (url: string) => Api.delete(url).then(responseBody),

    aGet: <T>(url: string, params?: ApiParams): Promise<T> => Api.get(url, {params: params})
};

export default appRequest;

// async function get<T, R>(url: string, params: T): Promise<R> {
//     const response = await axios.get<R>(url, { params });
//     return response.data;
// }

export const appReqStrict = {
    // ORIGINAL
    // Api.get<R>(url, { params })
    get: <T, R>(url: string, params?: T): Promise<R> => Api
        // .get<appApiResp<R>>(url, {params})
        .get<appApiResp<R>>(url, {params: params})
        .then((response: AxiosResponse<appApiResp<R>>) => {
            return response.data.data;
        }),

    read: <R>(url: string, params?: ApiParams): Promise<R> => Api
        .get<appApiResp<R>>(url, {params: params})
        .then((response: AxiosResponse<appApiResp<R>>) => {
            return response.data.data;
        }),
    patch: <RequestBodyType, ResponseType>(url: string, body?: RequestBodyType): Promise<ResponseType> => Api
        .patch<appApiResp<ResponseType>>(url, body)
        .then((response: AxiosResponse<appApiResp<ResponseType>>) => {
            return response.data as ResponseType;
        }),
    post: <RequestBodyType, ResponseType>(url: string, body?: RequestBodyType): Promise<ResponseType> => Api
        .post<appApiResp<ResponseType>>(url, body)
        .then((response: AxiosResponse<appApiResp<ResponseType>>) => {
            return response.data as ResponseType;
        }),
    // @todo need check
    put: <RequestBodyType, ResponseType>(url: string, body?: RequestBodyType): Promise<ResponseType> => Api
        .put<appApiResp<ResponseType>>(url, body)
        .then((response: AxiosResponse<appApiResp<ResponseType>>) => {
            return response.data as ResponseType;
        }),
    delete: <ResponseType>(url: string, params?: ApiParams): Promise<ResponseType> => Api
        .delete(url)
        .then((response: AxiosResponse<appApiResp<ResponseType>>) => {
            return response.data as ResponseType;
        })
};
