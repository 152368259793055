import { homePath } from 'shared/config/url-config-internal';

export const appConfig = Object.freeze({
    mainTitle  : 'Sync',
    defaultPath: homePath,
    i18n       : {
        default: 'en',
        plan   : {
            en: 'en-US'
        }
    }
});

export const drawerWidth = 260;

const { REACT_APP_API_BASE_URL, REACT_APP_API_DEBUG_HEADER } = process.env;

// @ts-ignore
export const appApiConfig: Readonly<{url:string, debugHeader: string}> = Object.freeze({
    url: REACT_APP_API_BASE_URL,
    debugHeader: REACT_APP_API_DEBUG_HEADER
});


const { REACT_APP_TINYMCE_KEY } = process.env;
// @ts-ignore
export const appTinyMCE: Readonly<{key:string}> = Object.freeze({
    key: REACT_APP_TINYMCE_KEY,
});
