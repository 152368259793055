import logoL from 'assets/images/sync1_logo_l.svg';
import logo from 'assets/images/sync_logo.svg';
import {appConfig} from 'shared/config/appConfig';

const Logo = () => {
    return <img height='40px' src={logo} alt={appConfig.mainTitle}/>;
};

export const LogoL = () => {
    return <img height='40px' src={logoL} alt={appConfig.mainTitle}/>;
};


export default Logo;
