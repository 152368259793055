import FormatSizeIcon from '@mui/icons-material/FormatSize';
import urlConfigInternal from 'shared/config/url-config-internal';

const icons = {
    FormatSizeIcon
};

const cms = {
    id: 'cms',
    title: 'CMS',
    type: 'item',
    url: urlConfigInternal.cms,
    icon: icons.FormatSizeIcon
};

export default cms;