export default function Select() {
    return {
        MuiSelect: {
            styleOverrides: {
                root: {
                    // lineHeight: '1.2em',
                    '& .MuiSelect-select.MuiSelect-standard': {
                        minHeight: '1.4em',
                        maxHeight: '1.4em',
                    }
                }
            }
        }
    };
}
