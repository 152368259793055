export const loginPath = '/login';
export const homePath = '/';

const urlConfigInternal = {
    get dashboard(): string {
        return homePath;
    },
    get usersManager(): string {
        return '/user-manager';
    },
    get rolesManager(): string {
        return '/role-manager';
    },
    get currencyConverter(): string {
        return '/currency-converter';
    },
    get playerProfiles(): string {
        return '/players';
    },
    get playerLogs(): string {
        return '/player-logs';
    },
    get gameIntegrator(): string {
        return '/game-integrators';
    },
    get gameProvider(): string {
        return '/game-provider';
    },
    get gameBrandProvider(): string {
        return '/game-brand-provider';
    },
    get games(): string {
        return '/games';
    },
    get gameBrands(): string {
        return '/game-brands';
    },
    get gameTags(): string {
        return '/game-tags';
    },
    get paymentSystems(): string {
        return '/payment-systems';
    },
    get paymentSystemMethod(): string {
        return '/payment-system-methods';
    },
    get paymentSystemMethodBrand(): string {
        return '/payment-system-methods-brand';
    },
    get paymentSystemBrand(): string {
        return '/payment-system-brands';
    },
    get accountingTransactionCategories(): string {
        return '/accounting-transaction-categories';
    },
    get accountingTransaction(): string {
        return '/accounting-transactions';
    },
    get advertisingAffiliates(): string {
        return '/affiliates';
    },
    get shiftScheduleManager(): string {
        return '/shift-schedule-manager';
    },
    get shiftEmployeeMonthPlan(): string {
        return '/shift-employee-month-plan';
    },
    get organizationManager(): string {
        return '/organization';
    },
    get freespinOfferManager(): string {
        return '/freespin-offers';
    },
    get playerFreespinByBrand(): string {
        return '/freespins';
    },
    get brandDepositTransactions(): string {
        return '/deposit-transactions';
    },
    get brandWithdrawalTransactions(): string {
        return '/withdrawal-transactions';
    },
    get brandBetWinTransactions(): string {
        return '/bet-win-transaction';
    },
    get messagingTemplate(): string {
        return '/messaging-template';
    },
    get protoSegmentation(): string {
        return '/segmentation';
    },
    get analytics(): string {
        return '/analytics';
    },
    get cms(): string {
        return '/cms';
    },
};

export default urlConfigInternal;
