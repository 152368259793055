export default function InputBase() {
    return {
        MuiInputBase: {
            styleOverrides: {
                root: {},
                input: {
                    padding: '0 0 2px 0',
                }
            }
        }
    };
}
