import {colorBase} from 'shared/ui/themes/palette';

export default function InputLabel(theme: any) {
    return {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    color: theme.palette.grey[600],
                    textTransform: 'capitalize',
                    top: '-3px'
                },
                shrink: {
                    fontSize: '1rem'
                },
                outlined: {
                    lineHeight: '1em',
                    '&.MuiInputLabel-sizeSmall': {
                        lineHeight: '1em'
                    },
                    '&.MuiInputLabel-shrink': {
                        background: theme.palette.background.paper,
                        padding: '0 8px',
                        marginLeft: -6,
                        lineHeight: '1.4375em',
                        borderRadius: '2px'
                    }
                },
                asterisk: {
                    color: colorBase.Red,
                    fontSize: '1.2rem',
                    position: 'relative',
                    right: '5px',
                }
            }
        }
    };
}
